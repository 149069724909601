@import '../../styles/colors.scss';
@import '../../styles/elevation.scss';
// TODO: pull common components out of editor ;)
@import '../editor/styles.module.scss';

.root {
  label {
    display: block;
  }
}

.mainView {
  position: relative;
  width: 100%;
  height: 100%;

  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.validationErrorText {
  color: $red;
}

.headerIcon {
  padding-right: 8px;
  transform: translateY(2px);
}
