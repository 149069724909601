@import '../../styles/colors.scss';
@import '../../styles/elevation.scss';
@import '../../styles/buttons.scss';

$sidebarWidth: 224px + 8px;

.FloorAvailability {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  padding-top: 1px; /* this shouldn't be necessary — weird */
  overflow: hidden;
}

.Sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 240px;
  z-index: 1;
  background-color: white;
}

.Floorplan {
  position: relative;
  flex: 1;
}

.SidebarSection {
  @extend %elevation100;
  margin-top: 16px;
  margin-left: 16px;
  border-radius: 4px;
  border: 1px solid $gray200;
}

.AreaLabel {
  height: 16px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 1px;
  border-radius: 6px;
  color: $white;
  background-color: $red;
  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  white-space: nowrap;
  pointer-events: none;

  &.available {
    background-color: $green;
  }
}

// COPIED FROM EDITOR SETTINGS PANEL
// WHICH EVEN HAS DIFFERENT INDENTATION FOR SOME REASON

.tooltip {
  position: absolute;
  top: calc(100% + 7px);
  display: flex;
  align-items: center;
  height: 22px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 22px;
  background-color: $gray800;
  color: $white;
  font-size: 12px;
  font-weight: 900;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity ease-in-out 150ms 50ms, top ease-in-out 150ms 50ms,
    bottom ease-in-out 150ms 50ms;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  &.top {
    top: initial;
    bottom: calc(100% + 7px);
  }
}

%tooltipParent {
  position: relative;

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      top: calc(100% + 8px);
      transition: visibility none 0ms 150ms, opacity ease-in-out 150ms 0,
        top ease-in-out 150ms 0, bottom ease-in-out 150ms 50ms;

      &.top {
        top: initial;
        bottom: calc(100% + 8px);
      }
    }
  }
}

%editorControls {
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;

  .buttonSmall {
    @extend %tooltipParent;
    margin-left: 4px;

    &.secondary:hover {
      background-color: $white;
      color: $blue;
    }
  }
}

.floorplanControls {
  @extend %editorControls;
  right: 16px;
  align-items: flex-start;
}

%controlPanel {
  background-color: $white;
  border-radius: 4px;
  background-color: white;
  border: 1px solid $gray200;
  box-shadow: 0px 1px 2px rgba(34, 42, 46, 0.1);
  cursor: default;
  box-sizing: border-box;
  width: $sidebarWidth;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
}

.controlPanel {
  @extend %controlPanel;
}

%controlPanelPopover {
  @extend %controlPanel;
  position: absolute;
  top: 100%;
  right: 50%;
  transform: translateX(50%);
  opacity: 0;
  visibility: hidden;
  margin-top: 4px;
  padding: 8px;
  transition: opacity ease-in-out 150ms 50ms, top ease-in-out 150ms 50ms,
    bottom ease-in-out 150ms 50ms;

  &.open {
    opacity: 1;
    visibility: visible;
    top: calc(100% + 2px);
    transition: visibility none 0ms 150ms, opacity ease-in-out 150ms 0,
      top ease-in-out 150ms 0, bottom ease-in-out 150ms 50ms;
  }
}

.controlPanelField {
  margin: 8px 0;
  .fieldTitle {
    display: inline-block;
    font-weight: bold;
    color: $gray700;
  }
  .valueLabel {
    float: right;
    color: $gray400;
  }
}

.controlPanelDivider {
  height: 1px;
  width: 100%;
  background-color: $gray200;
  margin: 16px 0;
}

.simulationPanel {
  position: relative;
  margin-left: 4px;

  .buttonAddSims {
    font-size: 12px;
    height: 28px;
    // margin-right: 24px;
  }
}

.settingsPanel {
  position: relative;
  margin-left: 4px;
}

.settingsMenuSection {
  display: block;
}
.settingsMenuRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 16px;
  height: 32px;
}
.settingsMenuRowLabel {
  flex: 1;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.settingsMenuRowLabelIcon {
  margin-right: 8px;
}
.settingsMenuRowAction {
  line-height: 0;
}
.settingsMenuRowInteractive {
  &:hover {
    background-color: rgba($midnight, 0.02);
  }
}
.settingsMenuUnitSelect {
  height: 24px;
  background-color: $midnightOpaque05;
  border: 0;
  border-radius: 4px;
  font-family: 'Aeonik', sans-serif;
  font-weight: bold;
}
.settingsMenu {
  @extend %controlPanelPopover;
  padding: 8px 0;
}
