@use '@density/dust/dist/scss/utils' as dust;
@import '@densityco/ui/variables/colors.scss';

$sidebarWidth: 262px;
$sidebarWideWidth: 320px;

.panelWrapper {
  position: absolute;
  pointer-events: none;
}

.panelWrapper.topLeft {
  left: 8px;
  top: 8px;
  bottom: 8px;
  width: $sidebarWidth + 30px;
}
.panelWrapper.topRight {
  right: 16px;
  top: 56px;
  bottom: 8px;
  width: $sidebarWideWidth;
}
.panelWrapper.bottomLeft {
  left: 8px;
  bottom: 8px;
  width: $sidebarWidth + 30px;
}
.panelWrapper.bottomRight {
  right: 16px;
  bottom: 8px;
  width: $sidebarWideWidth;
}

.panel {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;

  background-color: $white;
  border-right: 1px solid $gray200;
  box-shadow: 2px 4px 12px rgba($midnight, 0.12);
  cursor: default;

  border: 1px solid $gray200;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(34, 42, 46, 0.1);
  overflow: hidden;
}

.panelHeader {
  display: flex;
  align-items: center;
  height: 40px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;

  flex-grow: 0;
  flex-shrink: 0;
}

.panelTitle {
  display: flex;
  align-items: center;
  height: dust.$space-6;
  padding-left: 8px;
  padding-right: 8px;
  background-color: dust.$gray-000;

  flex-grow: 0;
  flex-shrink: 0;
}
.panelTitleIcon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: dust.$space-2;
  width: 18px;
  height: 18px;
}
.panelTitleText {
  @include dust.text-preset(3);
  @include dust.text-unselectable;
  font-weight: dust.$font-weight-bold;
  display: flex;
  align-items: center;
  height: 18px;
}

.panelBody {
  padding: 8px;
  overflow-y: auto;
}

.panelHeaderWell {
  @extend .panelBody;
  background-color: $gray000;
  padding: 16px;
  padding-bottom: 8px;
  flex-grow: 0;
  flex-shrink: 0;
}

.panelBodySection {
  margin-bottom: 8px;
}
.panelBodySection:last-child {
  margin-bottom: 0px;
}

.panelActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-left: 8px;
  padding-right: 8px;
  border-top: 1px solid $gray200;
  flex-grow: 0;
  flex-shrink: 0;
}
