@use '@density/dust/dist/scss/utils' as dust;

.wrapper {
  display: flex;
  align-items: center;
}

.fraction {
  font-size: dust.$text-scale-3;
  color: dust.$gray-500;
  margin-right: dust.$space-2;
  user-select: none;
}

.circle {
  flex-grow: 0;
  flex-shrink: 0;
}
