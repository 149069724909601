@import '@densityco/ui/variables/colors.scss';

.textAreaSmall {
  box-sizing: border-box;
  height: 32px;
  min-width: 80px;
  padding: 8px;
  background-color: $white;
  border: 1px solid $gray200;
  outline-color: $blue;
  border-radius: 4px;
  font-family: 'Aeonik', sans-serif;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  font-weight: 500;
  resize: none;
  width: 100%;
  min-height: 100px;

  &:disabled {
    background-color: $gray100;
    border-color: $gray100;
  }

  ::placeholder {
    color: $gray300;
    opacity: 1; /* Firefox */
  }

  + .inputTextSmall {
    margin-left: 8px;
  }

  &.box {
    width: 100%;
  }
}

// Sensor "Notes" control
// ------------------------
$sensorNotesBoxHeight: 100px;
.sensorNotesBox {
  padding: 8px;
  background-color: $gray100;
  border-radius: 6px;
  border: 1px solid $gray200;
  font-size: 14px;
  font-weight: 500;
  transition: background-color ease-in-out 100ms, border-color ease-in-out 100ms;
  height: $sensorNotesBoxHeight;
  overflow-y: auto;
}
.sensorNotesBox:hover {
  background-color: $blue100;
  border-color: $blue200;
  cursor: pointer;
}
.sensorNotesBox.disabled {
  background-color: $gray100;
  border-color: transparent;
  cursor: default;
}
// Style markdown in the notes box
.sensorNotesBox * {
  margin-top: 0px;
  margin-bottom: 0px;
}
.sensorNotesBox ul,
.sensorNotesBox ol {
  padding-left: 24px;
}
.sensorNotesBox code {
  color: $red400;
  background-color: $gray200;
  border: 1px solid $gray400;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  font-size: 14px;
}
.sensorNotesBox pre {
  background-color: $gray200;
  border: 1px solid $gray400;
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
}
.sensorNotesBoxPlaceholder {
  color: $gray400;
  user-select: none;
}
// The editable notes box is visible after the user clicks on the notes box
.sensorNotesBoxEditable {
  position: relative;
  background-color: $gray100;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;

  padding: 0px;
  background-color: #fff;
  border: 0px;
  height: auto;
  cursor: default;
  transition: none;
}
.sensorNotesBoxEditable textarea {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  &:active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
// "App bar" containing the "Cancel" and "Done" buttons
.sensorNotesBoxActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  padding-left: 8px;
  border: 1px solid $gray200;
  border-top: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 40px;
  transform: translateY(-3px);
}
.sensorNotesFormattingTooltip {
  color: red;
  display: flex;
  flex-direction: column;
  color: $gray300;
  font-size: 14px;
  width: 180px;
}
.sensorNotesFormattingTooltipRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sensorNotesFormattingTooltipRowExample {
  color: #fff;
  font-weight: bold;
  text-align: right;
}
