@use '@density/dust/dist/scss/utils' as dust;
@import '../../styles/colors.scss';
@import '../../styles/elevation.scss';

.PlanCreator {
  width: 100%;
  height: 100%;
}

.floorUploadPanel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  background-color: $white;
  border: 2px solid dust.$blue-400;
  border-radius: 6px;
  overflow: hidden;
  @extend %elevation100;
}

.floorUploadPanelHeader {
  padding: 16px;
  background-color: dust.$blue-400;
  color: $white;
}

.floorUploadPanelHeaderTitle {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.floorUploadPanelHeaderMessage {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
}

.uploadArea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 240px;
  transition: all ease-in-out 100ms;

  &:hover {
    cursor: pointer;
    background-color: $gray000;
    transition: all ease-in-out 150ms;
  }
}

.uploadAreaInstructions {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $midnightTransparent80;
}

.uploadAreaAction {
  color: $blue;
  margin-right: 4px;
}

.floorUploadingPanelBody {
  margin: 16px;
}

.progressBarWrapper {
  width: 100%;
  height: 4px;
  background-color: dust.$gray-300;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 8px;
}
.progressBarInner {
  height: 100%;
  background-color: dust.$blue-400;
}
.progressBarPercent {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  color: dust.$gray-400;
  margin-top: 4px;
  margin-bottom: 8px;
}
