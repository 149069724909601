@import '../../styles/colors.scss';

$sliderHeight: 16px;
$trackHeight: 8px;
$trackTop: ($sliderHeight - $trackHeight) * 0.5;

.Slider {
  width: 100%;
  height: $sliderHeight;
}
.SliderTrack {
  top: $trackTop;
  height: $trackHeight;
  background-color: rgba($gray900, 0.1);
  border-radius: 4px;

  &.track-0 {
    background-color: $blue;
  }
}

.SliderTrackMultiple {
  top: $trackTop;
  height: $trackHeight;
  background-color: rgba($gray900, 0.1);
  border-radius: 4px;

  &.track-1 {
    background-color: $blue;
  }
}

.SliderThumb {
  top: 0;
  left: $sliderHeight * 0.5;
  height: $sliderHeight;
  width: $sliderHeight;
  line-height: $sliderHeight;
  text-align: center;
  background-color: $blue;
  color: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 2px rgba(#000, 0.05);
  outline: 0;
  cursor: grab;
  transition-property: top, height, line-height, border-radius;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;

  &.active {
    background-color: $blueDark;
  }
}
