@import '../../styles/app-bar.scss';
@import '../../styles/colors.scss';

.AppBarFloor {
  @extend %app-bar-floor;
  position: relative;
  color: $gray800;
  display: flex;
  justify-content: space-between;
}

.AppBarFloorLeft {
  flex-grow: 1;
}

.AppBarFloorRight {
  @extend %app-bar-floor-right-actions;
}

.Breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;

  .Building {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 4px;
    color: $gray500;
    transition: all ease-in-out 100ms;

    .Icon {
      margin-right: 8px;
      line-height: 1px;
    }

    .Name {
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
    }

    &:hover {
      color: $blue;
      transition: all ease-in-out 150ms;
    }
  }
}

.viewSelectBox {
  width: 168px;
}

.status {
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 10px;
  padding-right: 8px;
  border-radius: 4px;
  border: 1px solid $gray200;
  transition: all ease-in-out 100ms;
  cursor: pointer;

  .statusDot {
    display: inline-block;
    color: $green;
    font-size: 24px;
    transform: translateY(-1px);

    &.statusDotPlanning {
      color: $yellow;
    }
  }

  .statusText {
    display: flex;
    align-items: center;
    transform: translateY(-1px);
    font-size: 14px;
    font-weight: 600;
  }
}

.statusInfo {
  .statusInfoHeader {
    padding-top: 8px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 500;
  }

  .statusInfoBody {
    padding-top: 8px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 1.3;
    font-size: 14px;
    font-weight: 500;
  }

  .statusInfoFooter {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border-top: 1px solid $gray200;
  }

  .statusInfoSwitchInputGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: $gray100;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
  }

  .statusSwitch {
    margin-top: 4px;
  }
}
