@import '../../styles/colors.scss';
@import '../../styles/elevation.scss';
@import '../../styles/buttons.scss';

.EmptyStateBuildingsIndex {
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  a,
  a:visited {
    color: $blue;
  }
}

.emptyBuildingStateAppBar {
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  border-bottom: 1px solid $gray200;
  color: $gray800;
}

.emptyStateBuildingBreadcrumbIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.emptyStateBuildingBreadcrumbLabel {
  margin-right: 8px;
}

.emptyStateMessage {
  max-width: 320px;
  margin-top: 240px;
  margin-bottom: 24px;
  color: $gray800;
  font-weight: 500;
}
