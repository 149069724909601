@use '@density/dust/dist/scss/utils' as *;
@import '@densityco/ui/variables/colors.scss';
@import '@densityco/ui/variables/spacing.scss';
@import '../../styles/elevation.scss';

.FloorAnalysisPage {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.FloorAnalysisTimeControls {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 726px;
  padding: 0 8px;
  margin: 8px;
  margin-top: 9px; // Sorry, border-box and asymmetric layout made me do this
  border-radius: $borderRadiusBase;
  border: 1px solid $gray200;
}

// Density UI pretty much all needs content-box sizing
.FloorAnalysisTimeControls * {
  box-sizing: content-box;
}
// But not the TimeFilterPicker! Really sorry to whoever finds this
.FloorAnalysisTimeFilterPicker * {
  box-sizing: border-box;
}

// Floor Analysis Selected Panel
// ------------------------
.FloorAnalysisSelectedPanel {
  position: absolute;
  z-index: 1;
  bottom: $space-2;
  left: $space-3;
  width: 240px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  background-color: $white;
  border: 1px solid $gray200;
  border-radius: 4px;
  @extend %elevation100;
}

.FloorAnalysisSelectedHeader {
  display: flex;
  align-items: flex-start;
  height: 28px;
  border-bottom: 1px solid $gray200;
  font-size: 14px;
  font-weight: bold;
  color: $gray800;
}

.FloorAnalysisSelectedHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.FloorAnalysisSelectedMeta {
  padding-top: 12px;
  max-width: 100%;
}

.FloorAnalysisSelectedMetaLabel {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
  color: $midnightTransparent40;
}

.FloorAnalysisSelectedMetaValue {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: $gray800;
}

.FloorAnalysisSelectedMetaValueLabel {
  flex-grow: 0;
  flex-shrink: 0;
}

.FloorAnalysisSelectedMetaBarChart {
  margin-top: -8px;
}

.FloorAnalysisSelectedMetaBarChartFill {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: $blue;
}

// Floor Analysis Tables
// ------------------------
.FloorAnalysisTable {
  position: relative;
  background: $white;
  padding-top: 16px;
  font-size: 14px;
  font-weight: 500;
  overflow: visible;
  border-top: 1px solid $gray200;
}
.FloorAnalysisTableCollapsed {
  margin-bottom: -241px;
}
.FloorAnalysisTableCollapser {
  display: none;
  position: absolute;
  cursor: pointer;
  left: calc(50% - 14px);
  top: -12px;
  height: 26px;
  width: 26px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $gray200;
  box-shadow: 0 2px 4px rgb(13 24 58 / 10%);
}
.FloorAnalysisTable:hover .FloorAnalysisTableCollapser {
  display: block;
}
.FloorAnalysisTableCollapser:hover {
  border-color: $blue;
  box-shadow: 0 0 0 1px $blue;
}
.FloorAnalysisTableHeaderRow {
  display: flex;
  border-bottom: 1px solid $gray200;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  padding-left: 24px;
  padding-right: 16px;
  color: $gray800;
}
.FloorAnalysisTableHeader {
  margin: 0 0 16px 0;
  height: 100%;
  display: flex;
  cursor: pointer;
}
.FloorAnalysisTableBody {
  overflow-y: scroll;
  height: 240px;
  padding-bottom: 48px;
}

.FloorAnalysisOccupancyBar.selected * {
  color: $midnightOpaque80 !important;
  font-weight: 600 !important;
}

.FloorAnalysisTableHeaderLabel {
  display: flex;
  align-items: center;
}

.FloorAnalysisTableLabel {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.FloorAnalysisListTableIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 4px;
}
