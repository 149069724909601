@import '../../styles/colors.scss';
@import '../../styles/elevation.scss';

.FloorAvailabilitySidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
}

// Shared Components
// ------------------------
.spaceIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
}

.spaceName {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Floor Panel
// ------------------------
.floorPanel {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;
  border: 1px solid $gray200;
  border-radius: 4px;
  @extend %elevation100;
}

.floorPanelHeader {
  display: flex;
  align-items: flex-start;
  height: 28px;
  border-bottom: 1px solid $gray200;
  color: $gray800;
}

.floorPanelMeta {
  padding-top: 12px;
  max-width: 100%;
}

.floorPanelMetaLabel {
  margin-bottom: 2px;
  font-size: 12px;
  font-weight: bold;
  color: $midnightTransparent40;
}

.floorPlanMetaValue {
  font-size: 16px;
  font-weight: 500;
  color: $gray800;
}

// Space List Panel
// ------------------------
.spaceListPanel {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  border: 1px solid $gray200;
  border-radius: 4px;
  @extend %elevation100;
  overflow: hidden;

  // NOTE: setting height manually here as a flex item cannot be smaller than the size of its content along the main axis
  // how we can get around that is 1. set min-height/min-width:0 and overflow:hidden all the way up the tree
  // or set an explicit (small) value on the parent container so that it will adjust based on flex-shrink/flex-grow
  height: 0;
}

.spaceList {
  flex-grow: 1;
  flex-shrink: 1;
  box-sizing: border-box;
  max-height: 100%;
  padding-top: 8px;
  padding-bottom: 12px;

  overflow-y: auto;
}

.spaceListItem {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  color: $gray800;

  &.highlighted {
    background-color: $midnightTransparent05;
  }
  // &.focused {
  //   background-color: $midnight;
  //   color: $white;
  // }
}

.spaceStatus {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 8px;
  color: $green;
}

.spaceStatusValue {
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
}

.spaceStatusIndicator {
  flex-grow: 0;
  flex-shrink: 0;
  height: 10px;
  width: 10px;
  background-color: $white;
  border: 2px solid $white;
  border-radius: 50%;
  box-shadow: 0 0 0 2px currentColor;
}

.spaceListItem.occupied {
  color: $gray400;

  .spaceName {
    color: $gray400;
  }

  .spaceStatus {
    color: $red;
  }

  .spaceStatusIndicator {
    background-color: currentColor;
    box-shadow: 0 0 0 2px currentColor;
  }
}

.spaceListSummary {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid $gray200;

  &.occupied .spaceStatus {
    color: $gray400;
  }
}

.spaceListSummaryTitle {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
}
