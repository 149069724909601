@import '@densityco/ui/variables/colors.scss';

.textfield {
  position: relative;
  box-sizing: border-box;
  border: 1px solid $gray200;
  background-color: $white;

  cursor: text;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 6px;
  font-variant-numeric: tabular-nums slashed-zero;
}
.textfield.light {
  background-color: #fff;
  border-color: $gray200;
}
.textfield.dark {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.textfield.focused {
  border-color: $blue400;
  box-shadow: 0px 0px 0px 2px $blue200;
}

.textfield.error {
  border-color: $red400;
}

.textfield.disabled {
  &,
  & input {
    cursor: not-allowed;
  }

  &.light {
    background-color: $gray100;
  }
  &.light input {
    color: $gray300;
  }

  &.dark {
    background-color: rgba(255, 255, 255, 0.05);
  }
  &.dark input {
    color: rgba(255, 255, 255, 0.2);
  }
}

.textfield .left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;
  flex-shrink: 1;
}

.textfield .leading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $blue700;
}
.textfield .leading.icon {
  width: 20px;
  height: 20px;
  padding-right: 4px;
}
.textfield .leading.prefix {
  // Don't set a width, because wide text strings will probably overflow (and that's fine!)
  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  user-select: none;
}

.textfield input {
  background-color: transparent;
  caret-color: $blue400;

  border: 0px;
  height: 100%;
  width: 100%;
  outline: none;

  padding-left: 4px;
  padding-right: 4px;

  flex-grow: 1;
  flex-shrink: 1;
}
.textfield.light input {
  color: $gray900;
}
.textfield.dark input {
  color: #fff;
}
.textfield.light input::placeholder {
  color: $gray300;
}
.textfield.dark input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.textfield .right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.textfield .trailing {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $blue700;
}
.textfield .trailing.icon {
  width: 20px;
  height: 20px;
  padding-left: 4px;
}
.textfield .trailing.suffix {
  // Don't set a width, because wide text strings will probably overflow (and that's fine!)
  height: 20px;
  padding-left: 4px;
  padding-right: 4px;
  user-select: none;
}

.textfield .helperText {
  position: absolute;
  bottom: -16px;
  line-height: 16px;
  font-size: 10px;
  color: $gray400;
  user-select: none;
  pointer-events: none;
}

.textfield.sizeSmall {
  height: 24px;
  padding-left: 4px;
  padding-right: 4px;

  &,
  & input {
    font-size: 12px;
  }

  .leading.icon,
  .trailing.icon {
    width: 16px;
    height: 16px;
  }
  .leading.prefix,
  .trailing.suffix {
    height: 16px;
  }
}

.textfield.sizeMedium {
  height: 32px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 14px;

  &,
  & input {
    font-size: 14px;
  }
  & input {
    transform: translateY(-1px);
  }

  .leading.icon,
  .trailing.icon {
    width: 20px;
    height: 20px;
  }
  .leading.icon,
  .trailing.suffix {
    height: 20px;
  }
}

.textfield.sizeLarge {
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;

  &,
  & input {
    font-size: 16px;
  }
  & input {
    transform: translateY(-1px);
  }

  .leading.icon,
  .trailing.icon {
    width: 24px;
    height: 24px;
  }
  .leading.prefix,
  .trailing.suffix {
    height: 24px;
  }
}
