@import '../../styles/colors.scss';
@import '../editor/styles.module.scss';

.container {
  @extend .controlPanel;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  user-select: none;
  z-index: 1;
  background-color: white;
  margin-right: 6px;
}

.menu {
  display: none;
  padding: 8px 0;
  border-bottom: 1px solid $gray200;

  &.open {
    display: block;
  }
}

.entry {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;

  .title {
    flex: 1;
    margin-left: 8px;
  }
  .shortcut {
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    background-color: $gray200;
    padding: 1px 4px;
    color: $gray600;
  }
}

.menuToggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  cursor: pointer;

  .title {
    flex: 1;
    line-height: 40px;
    margin-left: 8px;
    font-weight: bold;
  }
}
