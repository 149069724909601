// TODO: pull common components out of editor ;)
@import '../../styles/colors.scss';
@import '../../styles/elevation.scss';
@import '../../styles/buttons.scss';

.EmptyStateBuildingSingle {
  width: 100%;
  height: 100%;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  a,
  a:visited {
    color: $blue;
  }
}

.emptyStateMessage {
  max-width: 320px;
  margin-top: 240px;
  margin-bottom: 24px;
  color: $gray800;
  font-weight: 500;
}
