@import '@densityco/ui/variables/colors.scss';

$gradientBlueDefault: 'linear-gradient(97.67deg, #008BFF 0%, #1A79FF 100%)';
$gradientBlueLight: 'linear-gradient(97.67deg, #00A0FF 0%, #1A79FF 100%)';
$gradientBlueDark: 'linear-gradient(97.67deg, #0060D5 0%, #1A79FF 100%)';

// ----------------------------------------------------------------------------
// Base button styles
// ----------------------------------------------------------------------------

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  padding-left: 0px;
  padding-right: 0px;

  border: none;
  outline: none;

  font-size: 14px;
  font-variant-numeric: tabular-nums;
  line-height: 1;
  font-weight: bold;

  text-decoration: none;
  color: #fff;
  cursor: pointer;
  border-radius: 6px;

  transition: all 150ms ease-in-out;
}

.button:disabled {
  cursor: not-allowed;
}

.button.round {
  border-radius: 50%;
}

.button .leadingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  transform: translateX(2px);
}

.button .trailingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

// ----------------------------------------------------------------------------
// Button Sizes
// ----------------------------------------------------------------------------

.button.sizeLarge {
  height: 40px;
  min-width: 40px;
  padding-left: 8px;
  padding-right: 8px;
  .leadingIcon {
    width: 20px;
    height: 20px;
    transform: translateX(4px);
  }
  .buttonText {
    padding-left: 12px;
    padding-right: 12px;
  }
  .trailingIcon {
    width: 20px;
    height: 20px;
  }
}

.button.sizeMedium {
  height: 32px;
  min-width: 32px;
  padding-left: 6px;
  padding-right: 6px;
  .buttonText {
    padding-left: 8px;
    padding-right: 8px;
  }
  .leadingIcon {
    width: 18px;
    height: 18px;
  }
  .trailingIcon {
    width: 18px;
    height: 18px;
  }
}

.button.sizeSmall {
  height: 24px;
  min-width: 24px;
  padding-left: 4px;
  padding-right: 4px;
  .buttonText {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.button.sizeNano {
  height: 20px;
  min-width: 20px;
  padding-left: 2px;
  padding-right: 2px;
  .buttonText {
    padding-left: 6px;
    padding-right: 6px;
  }
}

// ----------------------------------------------------------------------------
// Button types
// ----------------------------------------------------------------------------
.button.typeFilled {
  color: #fff;
  background: #{$gradientBlueDefault};

  &.danger {
    color: $red;
  }

  &:hover {
    background: #{$gradientBlueLight};
  }
  &:active {
    background: #{$gradientBlueDark};
  }
  &:disabled {
    color: #fff;
    &.light {
      background: $gray200;
    }
    &.dark {
      background: $gray600;
    }
  }
}

.button.typeOutlined {
  color: $blue700;
  border: 1px solid $gray200;
  box-shadow: 0px 1px 3px rgba(39, 45, 51, 0.1),
    0px 1px 2px rgba(39, 45, 51, 0.06);
  &.light {
    background: #fff;
  }
  &.dark {
    background: $gray600;
  }

  &.danger {
    color: $red;
  }

  &:hover {
    &.light {
      background: $gray100;
    }
    &.dark {
      background: $gray500;
    }
  }
  &:active {
    box-shadow: none;
    &.light {
      background: $gray100;
    }
    &.dark {
      background: $gray600;
    }
  }
  &:disabled {
    background: #fff;
    box-shadow: none;
    &.light {
      background: $gray200;
    }
    &.dark {
      background: $gray600;
    }
  }
}

.button.typeHollow {
  border: 1px solid $gray200;
  background: transparent;
  &.light {
    color: $blue700;
  }
  &.dark {
    color: #fff;
  }

  &.danger {
    color: $red;
  }

  &:hover {
    &.light {
      background: $midnightTransparent05;
    }
    &.dark {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  &:active {
    &.light {
      background: $midnightTransparent10;
    }
    &.dark {
      background: rgba(255, 255, 255, 0.05);
    }
  }
  &:disabled {
    background: transparent;
    &.light {
      color: $midnightTransparent10;
    }
    &.dark {
      background: rgba(255, 255, 255, 0.2);
    }
    box-shadow: none;
  }
}

.button.typeCleared {
  &.light {
    color: $blue700;
  }
  &.dark {
    color: #fff;
  }
  background: transparent;

  &.danger {
    color: $red;
  }

  &:hover {
    &.light {
      background: $midnightTransparent05;
    }
    &.dark {
      background: rgba(255, 255, 255, 0.1);
    }
  }
  &:active {
    &.light {
      background: $midnightTransparent10;
    }
    &.dark {
      background: rgba(255, 255, 255, 0.05);
    }
  }
  &:disabled {
    background: transparent;
    &.light {
      color: $midnightTransparent20;
    }
    &.dark {
      background: rgba(255, 255, 255, 0.2);
    }
    box-shadow: none;
  }
}
