// Import any global variables from "../../../variables/*.scss"
@import '@densityco/ui/variables/colors.scss';
@import '@densityco/ui/variables/spacing.scss';
@import '@densityco/ui/variables/fonts.scss';

// Extract common styles into a placeholder selector for selectbox menu list and value
%density-ui-input-box-select-common {
  font-family: $fontBase;

  width: calc(100% - 18px - 12px);

  background-color: $white;
  border: 1px solid $gray300;
  border-radius: 4px;

  padding-left: 17px;
  padding-right: 8px;

  user-select: none;
}

%density-ui-input-box-select-box {
  position: relative;
  width: 200px;
  box-sizing: border-box;

  font-weight: normal;
}

// Select box value - this is the always visible region that shows the currently selected item.
%density-ui-input-box-select-box-value {
  @extend %density-ui-input-box-select-common;
  height: (40px - 1px - 1px); // (for borders)

  font-size: $fontSizeBase;
  color: $gray700;

  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;
  outline: none;

  & > span {
    margin-top: -2px;
    margin-right: auto;

    &:active {
      opacity: 0.9;
    }
  }
}

%density-ui-input-box-select-box-value--opened {
  border-color: $blue;
}

// When disabled, a selectbox is grayed out and can't be clicked.
%density-ui-input-box-select-box-value--disabled {
  background-color: $gray200;
  border-color: $gray200;
  pointer-events: none;
  cursor: not-allowed;
}

%density-ui-input-box-select-box-value--invalid {
  border-color: $red;
}

%density-ui-input-box-select-box-value-caret {
  height: 100%;
  display: flex;
  align-items: center;

  svg *[fill-rule='nonzero'] {
    fill: $gray700;
  }
}

// Select box menu - this is the menu that is hidden and shown when the user clicks on the select
// box value.
%density-ui-input-box-select-box-menu {
  @extend %density-ui-input-box-select-common;

  box-sizing: border-box;
  position: absolute;
  top: 48px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-right: 0px;

  width: 100%;

  z-index: 999999;
  color: $gray700;
  box-shadow: 0px 2px 4px $midnightOpaque10;
  transition: all 100ms ease-in-out;
  user-select: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  // When opened, fade in the select box and enable pointer events.
  // NOTE: when the select box is closed, it isn't given `display: none`, because giving it that
  // would make the animation difficult. Instead, it's given `pointer-events: none`, which causes
  // any clicks to not occur on that element and instead be handled by the element underneath it.
  opacity: 0;
  pointer-events: none;
}

%density-ui-input-box-select-box-menu--opened {
  opacity: 1;
  pointer-events: all;
}
%density-ui-input-box-select-box-menu--anchor-left {
  left: 0;
}
%density-ui-input-box-select-box-menu--anchor-right {
  right: 0;
}

%density-ui-input-box-select-box-menu-ul {
  list-style-type: none;
  padding-left: 0px;
  margin: 0px;
}

%density-ui-input-box-select-box-menu-li {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;
  // padding-bottom: 1px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: $borderRadiusBase;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;

  &:focus {
    background-color: $gray100;
    color: $gray700;
    text-decoration: underline;
  }

  &:hover {
    background-color: $gray100;
    color: $gray700;
  }
}

%density-ui-input-box-select-box-menu-li--disabled {
  color: $gray400;
  cursor: not-allowed;
  &:hover {
    color: $gray400;
    text-decoration: none;
    background-color: transparent;
  }
}

%density-ui-input-box-select-box-placeholder {
  color: $gray400;
}
