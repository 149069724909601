@import '../../styles/app-bar.scss';
@import '../../styles/input-box.scss';
@import '../../styles/buttons.scss';

.AppBarBuilding {
  @extend %app-bar-building;

  padding: 0 16px;
  z-index: 1;
}
