@import '@densityco/ui/variables/colors.scss';

// ------------
// use your imagination... this could live in a library!
%density-ui-app-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  height: 48px;
  background-color: white;
}

%density-ui-app-bar-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

%density-ui-app-bar-section-right {
  @extend %density-ui-app-bar-section;
  justify-content: flex-end;
}

// ------------
// then these could be specific to this application
%app-bar {
  @extend %density-ui-app-bar;
  box-shadow: 0px 1px 0px $gray200;
}

%app-bar-building {
  @extend %app-bar;
}

%app-bar-floor {
  @extend %app-bar;
  padding: 0 16px;
}

%app-bar-floor-right-actions {
  @extend %density-ui-app-bar-section-right;
  padding: 0;
  height: 32px;
}
