@import '../../styles/app-bar.scss';
@import '../../styles/colors.scss';

.AppBarPlanCreate {
  @extend %app-bar-floor;
  position: relative;
  color: $gray800;
}

.AppBarPlanCreateLeft {
  z-index: 12;
}

.AppBarPlanCreateRight {
  @extend %app-bar-floor-right-actions;
  z-index: 12;
}

.Breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 12;

  .Building {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-left: 4px;
    color: $gray500;
    transition: all ease-in-out 100ms;

    .Icon {
      margin-right: 8px;
      line-height: 1px;
    }

    .Name {
      font-size: 16px;
      font-weight: bold;
    }

    &:hover {
      color: $blue;
      transition: all ease-in-out 150ms;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  transition: all ease-in-out 100ms;

  .statusDot {
    color: $green;
    font-size: 22px;

    &.statusDotPlanning {
      color: $yellow;
    }
  }

  .statusText {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: $gray100;
    border-radius: 3px;
    cursor: pointer;
    transition: all ease-in-out 150ms;
  }

  &:active {
    background-color: $blue100;
  }
}

.statusInfo {
  .statusInfoHeader {
    padding-top: 8px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 500;
  }

  .statusInfoBody {
    padding-top: 8px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 1.3;
    font-size: 14px;
    font-weight: 500;
  }

  .statusInfoFooter {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border-top: 1px solid $gray200;
  }

  .statusInfoSwitchInputGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: $gray100;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
  }

  .statusSwitch {
    margin-top: 4px;
  }
}
