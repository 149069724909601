@import '@densityco/ui/variables/fonts.scss';
@import '@densityco/ui/variables/colors.scss';

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: $fontBase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // TODO: Get this out of here!!
  * {
    box-sizing: border-box;
  }
}

code {
  font-family: $fontMonospace;
}

.toast {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 0;
  border-radius: 4px;
  background-color: $gray800;
  font-family: $fontBase;
  font-size: 14px;
  font-weight: 500;
}

.Toastify__toast-container--bottom-center {
  bottom: 16px;
}

.Toastify__toast--success {
  color: $green200;
}
.Toastify__toast--warning {
  color: $yellow200;
}
.Toastify__toast--error {
  color: $red200;
}

.Toastify__toast-body {
  margin: 0;
  padding: 0;
}

.Toastify__close-button {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
