@use '@density/dust/dist/scss/utils' as dust;
@import '../../styles/colors.scss';
@import '../../styles/buttons.scss';
@import '../../styles/elevation.scss';

$sidebarWidth: 224px + 8px;

.editor {
  display: grid;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $gray000;

  * {
    box-sizing: border-box;
  }

  grid-template-areas: 'sidebar editor-viewport';
  grid-template-rows: 1fr;
  grid-template-columns: $sidebarWidth 1fr;
}

.sidebar {
  position: relative;
  grid-area: sidebar;
}

.editorViewport {
  grid-area: editor-viewport;
  position: relative;
  overflow: hidden;
}

// --------------------------------------------
// Forms
// --------------------------------------------

.formGroup {
  padding-bottom: 6px;
}

.formGroup + .formGroup {
  padding-top: 6px;
  padding-bottom: 6px;
}

.inputTextSmall {
  box-sizing: border-box;
  height: 32px;
  min-width: 80px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: $white;
  border: 1px solid $gray200;
  outline-color: $blue;
  border-radius: 4px;
  font-family: 'Aeonik', sans-serif;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  font-weight: 500;

  &:disabled {
    background-color: $gray100;
    border-color: $gray100;
  }

  ::placeholder {
    color: $gray300;
    opacity: 1; /* Firefox */
  }

  + .inputTextSmall {
    margin-left: 8px;
  }

  &.box {
    width: 100%;
  }
}

.textArea {
  box-sizing: border-box;
  height: 32px;
  min-width: 80px;
  padding: 8px;
  background-color: $white;
  border: 1px solid $gray200;
  outline-color: $blue;
  border-radius: 4px;
  font-family: 'Aeonik', sans-serif;
  font-variant-numeric: tabular-nums;
  font-size: 14px;
  font-weight: 500;
  resize: none;
  width: 100%;
  min-height: 100px;

  &:disabled {
    background-color: $gray100;
    border-color: $gray100;
  }

  ::placeholder {
    color: $gray300;
    opacity: 1; /* Firefox */
  }

  + .inputTextSmall {
    margin-left: 8px;
  }

  &.box {
    width: 100%;
  }
}

/* Remove number spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

// Inline Inputs
// ---------------------
.inputInline {
  display: flex;
  align-items: center;

  input {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 4px;

    & + input {
      margin-left: 4px;
    }
  }

  .buttonSmall {
    margin: 0;
  }
}

.inputInlineIcon {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
}

.inputWithIcon {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  .inputIconWrapper {
    position: absolute;
    top: 7px;
    left: 8px;
    height: 18px;
    width: 18px;
  }

  input {
    width: 100%;
    padding-left: 34px;
  }
}

label {
  display: block;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: bold;

  &.inline {
    display: inline-block;
    margin-bottom: 0;
  }

  &.muted {
    color: $gray400;
  }

  &.large {
    font-size: 14px;
  }
}

// --------------------------------------------
// Tooltips
// --------------------------------------------

.tooltip {
  position: absolute;
  top: calc(100% + 7px);
  display: flex;
  align-items: center;
  height: 22px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 22px;
  background-color: $gray800;
  color: $white;
  font-size: 12px;
  font-weight: 900;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity ease-in-out 150ms 50ms, top ease-in-out 150ms 50ms,
    bottom ease-in-out 150ms 50ms;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }

  &.top {
    top: initial;
    bottom: calc(100% + 7px);
  }
}

%tooltipParent {
  position: relative;

  &:hover {
    .tooltip {
      opacity: 1;
      visibility: visible;
      top: calc(100% + 8px);
      transition: visibility none 0ms 150ms, opacity ease-in-out 150ms 0,
        top ease-in-out 150ms 0, bottom ease-in-out 150ms 50ms;

      &.top {
        top: initial;
        bottom: calc(100% + 8px);
      }
    }
  }
}

// --------------------------------------------
// Panel
// --------------------------------------------

%panel {
  background-color: $white;
  border-right: 1px solid $gray200;
  box-shadow: 2px 4px 12px rgba($midnight, 0.12);
  cursor: default;
}

%sidebarPanel {
  @extend %panel;
  box-shadow: 0px 1px 2px rgba($midnight, 0.1);
}

.panelActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  border-top: 1px solid $gray200;
}

.panelActionsSpacer {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.panelDivider {
  height: 1px;
  width: 100%;
  background-color: $gray300;
  margin: 16px 0;
}

// --------------------------------------------
// Editor
// --------------------------------------------

// Editor Controls
// ------------------------
%editorControls {
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;

  .buttonSmall {
    @extend %tooltipParent;

    &.secondary:hover {
      background-color: $white;
      color: $blue;
    }
  }
}

.floorplanControls {
  @extend %editorControls;
  right: 16px;
  align-items: flex-start;
}

// Editor Elements
// ------------------------
.placementTooltip {
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0 16px;
  background-color: rgba($midnight, 0.4);
  border-radius: 16px;
  font-size: 14px;
  font-weight: bold;
  color: $white;
  line-height: 40px;
  white-space: nowrap;
}

.saveMenu {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  background-color: $white;
  box-shadow: 2px 4px 8px rgba($midnight, 0.1);
}

// --------------------------------------------
// Side Panel
// --------------------------------------------

// Location Panel
// ------------------------

.locationPanel {
  @extend %sidebarPanel;
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  width: $sidebarWidth;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 1px solid $gray200;
}

.locationPanelBreadcrumb {
  display: flex;
  align-items: center;
  padding-bottom: 2px;
  font-size: 12px;
  color: #959da5;
}

.locationPanelBreadcrumbItem {
  position: relative;
  max-width: 90px;
  padding-right: 20px;
}

.locationPanelBreadcrumbChevron {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.locationPanelPlanTitleInput {
  font-size: 20px;
  font-weight: 500;
  color: $midnight;
  border: none;
  box-sizing: border-box;
  width: 100%;
}

// --------------------------------------------
// Objects Panel
// --------------------------------------------

.objectsPanel {
  @extend %sidebarPanel;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 0;
  border: 1px solid $gray200;
  border-radius: 6px;
  overflow: hidden;
}

.objectsPanelHeader {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.objectsPanelActions {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 8px;
  border-bottom: 1px solid $gray200;
}

.objectsPanelSearch {
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: $gray100;
}

.objectsPanelActionsTitle {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 14px;
  color: $midnight;
  margin-bottom: 0;
}

.objectsPanelActionsButton {
  @extend .buttonSmall;
  @extend .buttonIcon;
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid $gray200;
  font-size: 12px;

  &.active {
    background-color: $blueLight;
    color: $blue;
  }
}

.objectsPanelTabs {
  width: 100%;
  color: $gray900;
}

.objectsPanelMainScrollArea {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: scroll;
  padding-bottom: 16px;
  background-color: $gray100;
}

.objectsPanelEmptyState {
  font-style: italic;
  font-size: 14px;
  font-weight: 500;
  color: $gray400;
  padding-left: 16px;
  padding-top: 8px;
  background-color: $gray100;
  user-select: none;
}

.objectsPanelListItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0 4px;
  margin: 0 12px;
  border: 1px solid transparent;
  color: $gray800;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  &.error {
    &::before {
      content: '';
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      width: 6px;
      background-color: $red;
      border-radius: 3px;
    }
  }

  &.online {
    &::before {
      content: '';
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      width: 6px;
      background-color: $green;
      border-radius: 3px;
    }
  }

  &.highlighted {
    background-color: $blueLight;
    border-radius: 5px;
  }
  &.focused {
    font-weight: bold;
    color: $gray900;

    .objectsPanelListItemIconWrapper {
      color: $blue;
    }
    .objectsPanelListItemIconWrapperEntry {
      color: $purple;
    }
  }
  &.disabled {
    background-color: transparent;
    cursor: default;
    color: $gray400;
  }
}

.objectsPanelListItemIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.objectsPanelFooter {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 8px 0;
  background: $gray100;
  border-top: 1px solid $gray200;
}

// --------------------------------------------
// Focused Object Panel
// --------------------------------------------

.focusedObjectPanel {
  @extend %panel;
  position: absolute;
  left: 8px;
  top: 8px;
  width: $sidebarWidth + 30px;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(34, 42, 46, 0.1);
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    height: 40px;
    padding-top: 8px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .title {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: $midnight;

    .icon {
      margin-right: 4px;
    }
  }

  .lockButton {
    @extend .buttonSmall;
    @extend .buttonIcon;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .body {
    padding: 8px;
  }
}

// Generic Panel Textbox Header
// ------------------------
.panelHeaderTextboxActionWrapper {
  width: 100%;
}
.panelHeaderTextboxAction {
  margin-left: 4px;
}

// Focused Sensor Panel
// ------------------------
.sensorHeightFormGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sensorHeightFormFields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sensorHeightInputLabel {
  padding-top: 6px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
  color: $gray500;
}

.sensorHeightValue {
  font-size: 14px;
  font-weight: 500;
  color: $blue;
}

.sensorRotationFormGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sensorRotationFormFields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sensorRotationNumberInput {
  min-width: auto;
  width: 44px;
  margin-right: 4px;
}

.sensorRotationDegreeLabel {
  margin-left: 2px;
  margin-right: 4px;
  font-size: 16px;
  color: $gray500;
}

.sensorConfigMeta {
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: $gray100;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  overflow-y: auto;
}

.sensorConfigMetaStatus {
  padding: 0 0 10px 5px;
}

.sensorConfigMetaStatusText {
  font-weight: 700;
  text-transform: capitalize;
}

.sensorConfigMetaRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 8px;

  &:last-child {
    padding-bottom: 0;
  }

  .metaLabel {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 56px;
  }

  .metaDetail {
    padding-right: 8px;
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
    white-space: nowrap;
  }
}

.sensorStreamingControl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
  background-color: $gray800;
  border-radius: 4px;
  margin-bottom: 8px;

  label {
    margin: 0;
    color: $white;
  }
}

.sensorStreamingStatus {
  padding-left: 4px;

  &.disconnected {
    color: $red;
  }
  &.connected {
    color: $green;
  }
  &.connecting {
    color: $gray400;
  }
}

// Focused Space Panel
// ------------------------
.spaceNameInput {
  width: 100%;
  margin-right: 0;
}

.spaceConfigMeta {
  padding: 8px;
  background-color: $gray100;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.spaceConfigMetaRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;

  &:last-child {
    padding-bottom: 0;
  }
}

// Sensor List Buttons
// ------------------------

.sensorList {
  list-style: none;
  margin: 0;
  padding-top: 8px;
  padding-left: 0;
}

.sensorListHeader {
  display: flex;
  align-items: flex-end;
  padding: 0 16px;
  height: 32px;
  cursor: pointer;
  color: $gray400;

  &:hover {
    color: $gray900;
  }
}

.sensorListTitle {
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
}

.sensorListItem {
  @extend .objectsPanelListItem;
  font-variant-numeric: tabular-nums slashed-zero;
}

.sensorCount {
  display: flex;
  padding: 8px 16px;
}

.sensorCountLabel {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  color: $gray500;
  font-size: 14px;
  font-weight: 500;

  > svg {
    margin-right: 4px;
  }
}

.sensorCountNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  background-color: rgba($midnight, 0.4);
  border-radius: 8px;
  text-align: center;
  color: $white;
  font-size: 10px;
  font-weight: 900;
  padding-left: 6px;
  padding-right: 6px;
}

.spaceListItem {
  @extend .objectsPanelListItem;
}

.referenceListItem {
  @extend .objectsPanelListItem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.disabled {
    color: $gray300;
  }

  &.highlighted {
    font-weight: bold;

    &::before {
      display: none;
    }
  }
}

.referenceListItemTitle {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.photoGroupList {
  list-style: none;
  margin: 0;
  padding-top: 8px;
  padding-left: 0;
}
.photoGroupListItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  margin: 0 8px;
  border: 1px solid transparent;
  border-radius: 6px;
  color: $gray800;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  font-size: 14px;

  .photoGroupListItemIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .photoGroupListItemName {
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.focused {
    .photoGroupListItemIconWrapper {
      color: $blue;
    }
    .photoGroupListItemTitle {
      font-weight: bold;
    }
  }
  &.highlighted {
    background-color: $blue100;
  }
  // Photo groups are disabled when they are hidden globally
  &.disabled {
    background-color: transparent;
    cursor: default;
    color: $gray400;
  }

  .photoGroupListItemLockWrapper {
    margin-right: 4px;
    margin-left: 4px;
    color: $gray400;
  }

  .photoGroupListItemTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 16px;
    border: 1px solid $gray400;
    border-radius: 6px;
    font-size: 10px;
    font-weight: bold;
    color: $gray400;
  }

  .photoGroupListItemBubble {
    width: 8px;
    height: 8px;
    margin: 5px;
    border-radius: 50%;
    background-color: $gray500;
    &.filled {
      background-color: $red;
    }
  }
}

.layerList {
  list-style: none;
  margin: 0;
  padding-top: 8px;
  padding-left: 0;
}
.layerListItemHeader {
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 500;
  color: dust.$gray-500;
  user-select: none;
  margin-top: 16px;
  margin-bottom: 4px;

  &:first-child {
    margin-top: 0px;
  }
}
.layerListItem {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  border: 1px solid transparent;
  border-radius: 6px;
  color: $gray800;
  font-weight: 500;
  line-height: 1;
  cursor: pointer;
  font-size: 14px;
  user-select: none;

  &.focused {
    font-weight: bold;
    color: dust.$gray-900;
    background-color: dust.$blue-100;

    .layerListItemIcon {
      color: dust.$blue-400;
    }
  }

  .layerListItemIcon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .layerListItemLabel {
    height: 16px;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Nav Tabs
// ------------------------

.navTabs {
  display: flex;
  align-items: center;
  height: 32px;
  background-color: $gray200;
  padding: 2px;
  border-radius: 4px;
}

.tabTarget {
  @extend %tooltipParent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  height: 28px;
  min-width: 28px;
  margin-right: 4px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  outline: none;
  font-weight: bold;
  font-size: 14px;
  color: $gray800;
  cursor: pointer;
  transition: all ease-in-out 100ms;

  &:hover {
    background-color: $gray300;
    transition: all ease-in-out 150ms;
  }

  &.active {
    background-color: $white;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
    color: $gray900;
    transition: all ease-in-out 150ms;
  }

  &:last-child {
    margin-right: 0;
  }
}

// Toggle Buttons
// ------------------------

.toggleButton {
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-left: 16px;
  padding: 8px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  transition: all 100ms ease-in-out;

  .icon {
    margin-right: 8px;
  }

  &:hover {
    background-color: $blueLight;
    cursor: pointer;
  }

  &.active {
    background-color: $blueLight;
    color: $blue;
  }

  & + .toggleButton {
    margin-top: 4px;
  }
}

// Control Panels
// -------------------------
%controlPanel {
  background-color: $white;
  border-radius: 4px;
  background-color: white;
  border: 1px solid dust.$gray-200;
  box-shadow: 0px 1px 2px rgba(34, 42, 46, 0.1);
  cursor: default;
  box-sizing: border-box;
  width: $sidebarWidth;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
}

.controlPanel {
  @extend %controlPanel;
}

%controlPanelPopover {
  @extend %controlPanel;
  position: absolute;
  top: 100%;
  right: 100%;
  transform: translateX(50%);
  opacity: 0;
  visibility: hidden;
  margin-top: 4px;
  padding: 8px;
  transition: opacity ease-in-out 150ms 50ms, top ease-in-out 150ms 50ms,
    bottom ease-in-out 150ms 50ms;

  &.open {
    opacity: 1;
    visibility: visible;
    top: calc(100% + 2px);
    transition: visibility none 0ms 150ms, opacity ease-in-out 150ms 0,
      top ease-in-out 150ms 0, bottom ease-in-out 150ms 50ms;
  }
}

.controlPanelField {
  margin: 8px 0;
  .fieldTitle {
    display: inline-block;
    font-weight: bold;
    color: $gray700;
  }
  .valueLabel {
    float: right;
    color: $gray400;
  }
}

.controlPanelDivider {
  height: 1px;
  width: 100%;
  background-color: $gray200;
  margin: 16px 0;
}

.simulationPanel {
  position: relative;
  margin-left: 4px;

  .buttonAddSims {
    font-size: 12px;
    height: 28px;
    // margin-right: 24px;
  }
}

.simulationMenu {
  @extend %controlPanelPopover;
}

.simulationMenuItem {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;

  .title {
    flex: 1;
    margin-right: 8px;
  }
  .shortcut {
    height: 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    font-size: 12px;
    background-color: $gray200;
    padding: 1px 4px;
    color: $gray600;
  }
}

.simulationControlsItem {
  justify-content: space-between;
}

.settingsPanel {
  position: relative;
}

.settingsMenuSection {
  display: block;
}
.settingsMenuRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 16px;
  height: 32px;
  user-select: none;
}
.settingsMenuRowLabel {
  flex: 1;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.settingsMenuRowLabelIcon {
  margin-right: 8px;
}
.settingsMenuRowAction {
  line-height: 0;
}
.settingsMenuRowBoundingBox {
  line-height: 0;
  margin-top: 2px;
}
.settingsMenuRowInteractive {
  &:hover {
    background-color: rgba($midnight, 0.02);
  }
}
.settingsMenuRowDisabled {
  color: rgba($midnight, 0.2);
}
.settingsMenuUnitSelect {
  height: 24px;
  background-color: $midnightOpaque05;
  border: 0;
  border-radius: 4px;
  font-family: 'Aeonik', sans-serif;
  font-weight: bold;
}
.settingsMenuRowIndent {
  padding-left: 32px;
}
.settingsMenuRowDoubleIndent {
  padding-left: 48px;
}

.settingsMenuHeader {
  color: $gray400;
  border-bottom: 1px solid $gray400;
  padding: 11px 16px;
  font-weight: bold;
  user-select: none;
}

.settingsMenu {
  @extend %controlPanelPopover;
  padding: 8px 0;
}

// Control Panel Sections
// ---------------------------
.mapControls {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  .mapControlsInner {
    display: flex;
    align-items: flex-end;
    pointer-events: auto;

    .buttonSmall {
      @extend %tooltipParent;
      margin-left: 4px;

      &.secondary:hover {
        background-color: $white;
        color: $blue;
      }
    }
  }
}

// Export Panel Popup
.exportPanel {
  position: relative;
}

.exportMenu {
  @extend %controlPanelPopover;
  padding: 0px;
}

.exportPreview {
  display: flex;

  background-color: dust.$gray-100;
  border: 1px solid dust.$gray-200;
  padding: dust.$space-3;
  margin: dust.$space-3;
  border-radius: dust.$radius-100;
}
.exportPreviewImage {
  flex-grow: 0;
  flex-shrink: 0;
  width: dust.$space-6;
  height: dust.$space-6;
  border-radius: dust.$radius-100;
  background-color: dust.$gray-200;
  background-size: dust.$space-7;
  background-position: center;
  background-size: cover;
}
.exportPreviewDetails {
  display: flex;
  margin-left: dust.$space-3;
  flex-direction: column;
  justify-content: center;
  width: 0px;

  flex-grow: 1;
  flex-shrink: 1;
}
.exportPreviewTitle {
  display: flex;
  justify-content: space-between;
  color: dust.$gray-800;
}
.exportPreviewBadge {
  margin-left: auto;
  color: dust.$gray-400;
  border: 1px solid dust.$gray-400;
  border-radius: dust.$radius-200;
  user-select: none;

  font-size: dust.$text-scale-2;
  font-weight: bold;
  padding-top: dust.$space-1;
  padding-bottom: dust.$space-1;
  padding-left: dust.$space-2;
  padding-right: dust.$space-2;
}
.exportPreviewName {
  display: flex;
  color: dust.$gray-400;
  white-space: nowrap;
}
.exportPreviewName .prefix {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.exportPanelActionBar {
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid dust.$gray-200;
  padding: dust.$space-3;
}
.exportControls {
  margin: dust.$space-3;
}

// currently used for swap floorplan confirmation modal
.modalBody {
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 440px;
}

.modalBodyTitle {
  font-weight: bold;
  color: $midnight;
  font-size: 18px;
}

.photoEmptyButton {
  border-radius: 6px;
  height: 152px;
  background-color: $gray100;
  border: 1px solid transparent;
  transition: background-color ease-in-out 100ms, border-color ease-in-out 100ms;
  font-weight: bold;
  color: $blue400;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}
.photoEmptyButton:hover {
  background-color: $blue100;
  border-color: $blue200;
  cursor: pointer;
}

.photo {
  background-color: #fff;
  border-radius: 6px;
  height: 152px;
  cursor: default;
  overflow: hidden;

  position: relative;

  // background-image is set as an inline style
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.photo.draggable {
  cursor: grab;
}
.photo.dragging {
  cursor: grabbing;
}

.photoControls {
  display: flex;
  padding: 8px;
  z-index: 1;
}

.photoControlsName {
  flex-grow: 1;
  flex-shrink: 1;
  margin-right: 8px;
}

.photoControlsActions {
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
}

.photoImageShadow {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: linear-gradient(
    180deg,
    rgba(39, 45, 51, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.photoUploadHint {
  width: 100%;
  font-weight: bold;
  color: $gray500;
  border: 1px solid $gray200;

  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

  border-radius: 6px;

  a {
    color: $blue;
  }
}

.cadImportAppBar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 64px;
  background-color: dust.$white;
  border-bottom: 1px solid dust.$gray-100;
  padding-left: 16px;
  padding-right: 16px;
}

.cadImportAppBarTitle {
  @include dust.text-preset(6);
  padding-left: 8px;
}

.importLoading {
  animation: import-loading 1.2s linear infinite;
  width: 24px;
  height: 24px;
}
@keyframes import-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cadImportControlList {
  display: flex;
  flex-wrap: wrap;
  gap: dust.$space-3;
}

.cadImportLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 240px;
  height: 240px;
  margin-top: -120px;
  margin-left: -120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid dust.$gray-200;
  background-color: dust.$white;
  border-radius: dust.$radius-100;

  @include dust.text-preset(3);
  font-weight: bold;
}

.diffTable {
  border-spacing: 0px;
  width: 100%;
  height: 100%;
}

.diffHeader {
  text-align: left;
  height: 32px;
  border-bottom: 1px solid dust.$gray-100;
  @include dust.text-preset(4);
  font-weight: bold;
}

.diffRow {
  height: 24px;
  color: dust.$gray-800;
  @include dust.text-preset(4);
}
.diffRow.addition {
  background-color: dust.$green-000;
}
.diffRow.deletion {
  background-color: dust.$red-000;
}
.diffRow.noChange {
  color: dust.$gray-500;
}
.diffRow.modification {
  background-color: dust.$yellow-000;
  height: 48px;
  vertical-align: top;
}

.operationTypeIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin: 4px;
  border-radius: dust.$radius-100;
}
.operationTypeIndicator.addition {
  color: dust.$green-400;
  background-color: dust.$green-100;
}
.operationTypeIndicator.deletion {
  color: dust.$red-400;
  background-color: dust.$red-100;
}
.operationTypeIndicator.modification {
  color: dust.$yellow-400;
  background-color: dust.$yellow-100;
}
.operationTypeIndicator.noChange {
  color: dust.$gray-400;
  background-color: dust.$gray-100;
}

.legendTable {
  border-spacing: 0px;
  width: 100%;
  height: 100%;
  @include dust.text-preset(3);
}
.legendTable tr td:first-child {
  width: 24px;
}
.legendTable .tag {
  background-color: dust.$gray-200;
  border-radius: dust.$radius-700;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.legendTable .totalRow {
  background-color: dust.$gray-000;
  height: 30px;
}

.boundingBoxRow {
  display: flex;
  margin: 8px 0px;
  padding-right: 6px;
}

.noFloorplanImageMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  background-color: $white;
  border: 2px solid $blue;
  border-radius: 6px;
  overflow: hidden;
  @extend %elevation100;
}
.noFloorplanImageMessageHeader {
  padding: 16px;
  background-color: dust.$blue-400;
  color: dust.$white;
  font-size: 16px;
  font-weight: 500;
}
.noFloorplanImageMessageBody {
  margin: 16px;
}

.latestDXFStatus {
  display: flex;
  cursor: default;
  border: 1px solid dust.$gray-400;
  border-radius: 4px;
  margin: 8px;
  padding: 8px;
  font-size: 14px;
  user-select: none;

  .middle {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 12px;
      font-weight: bold;
      color: $gray500;
      margin-bottom: 4px;
      user-select: none;
    }
    .length {
      font-size: 14px;
      cursor: text;
    }
  }
}

.latestDXFPercentWrapper {
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  height: 4px;
  background-color: dust.$gray-300;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 4px;
}
.latestDXFPercent {
  height: 100%;
  background-color: dust.$blue-400;
}

.heightMapExtentsSlider {
  position: relative;
  width: 100%;
  height: dust.$space-8;
  margin-top: dust.$space-4;
}

.heightMapExtentsSliderBar {
  width: 100%;
  height: dust.$space-6;
  background-color: dust.$gray-100;
  box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
  border-radius: dust.$radius-100;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
}

.heightMapExtentsSliderGradient {
  background: linear-gradient(
    270deg,
    #ffc596 0%,
    #ee853a 25.52%,
    #bc3865 51.04%,
    #1b4693 76.56%,
    #1c2a59 100%
  );
  border-radius: dust.$radius-100;

  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  cursor: grab;
}

.heightMapExtentsSliderMarker {
  position: absolute;
  cursor: pointer;
}

.heightMapPanelWrapper {
  display: flex;
  flex-direction: column;
  gap: dust.$space-3;
}

.heightMapPanelOpacity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: dust.$gray-400;
  font-size: dust.$text-scale-4;
  font-weight: dust.$font-weight-medium;
}
