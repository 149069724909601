@import '@densityco/ui/variables/colors.scss';

.FloorsIndex {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.FloorsList {
  list-style: none;
  padding: 16px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  gap: 16px;
}

.FloorsListItem {
  width: 405px;
  height: 300px;
  border-radius: 4px;
  border: 1px solid $gray200;
  transition: all ease-in-out 100ms;

  .Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 0 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    border-bottom: 1px solid $gray200;

    .HeaderText {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .HeaderStatus {
    display: flex;
    align-items: center;
  }

  .HeaderCountLabel {
    font-size: 16px;
    color: $gray600;
    display: flex;
    margin-right: 24px;
    font-weight: bold;
  }

  .HeaderStatusIndicator {
    height: 6px;
    width: 6px;
    margin-right: 8px;
    border-radius: 3px;
    background-color: $gray300;

    &.live {
      background-color: $green;
    }
    &.planning {
      background-color: $yellow;
    }
  }

  .HeaderStatusLabel {
    margin-top: -2px;
    font-size: 12px;
    text-transform: capitalize;
    white-space: nowrap;
  }

  .Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 252px;
    font-weight: 500;
  }

  .PlanImageWrapper {
    height: 100%;
    width: 100%;
  }

  .PlanImage {
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
    filter: grayscale(1);
    opacity: 0.5;
  }

  .PlanAddButton {
    display: flex;
    align-items: center;
    color: $gray400;
  }

  .PlanAddButtonLabel {
    margin-left: 4px;
  }

  &:hover {
    border-color: $blue;
    box-shadow: 0 0 0 3px rgba($blue, 0.24);
    cursor: pointer;
    transition: all ease-in-out 200ms;
  }
}
