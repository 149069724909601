@import '../../styles/colors.scss';

.dropdownInfoWrapper {
  position: relative;
  user-select: none;
}

.dropdownBox {
  display: none;
  position: absolute;
  z-index: 99999;
  top: 98%;
  right: 0;
  width: 240px;
  padding-top: 8px;
  margin-top: 6px;
  background-color: $white;
  border: 1px solid $gray200;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity ease-in-out 150ms 50ms, top ease-in-out 150ms 50ms,
    bottom ease-in-out 150ms 50ms;

  &.open {
    display: flex;
    opacity: 1;
    visibility: visible;
    top: calc(100%);
    transition: visibility none 0ms 150ms, opacity ease-in-out 150ms 0,
      top ease-in-out 150ms 0, bottom ease-in-out 150ms 50ms;
  }
}
