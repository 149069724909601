@use '@density/dust/dist/scss/utils' as *;

@mixin toolbar-box() {
  @include box();
  height: grid-8pt(3);
  padding-left: $space-3;
  padding-right: $space-3;
  display: flex;
  align-items: center;
}

.toolbar {
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  @include hstack(3);
}

.heatmapToggle {
  @include toolbar-box();
  @include hstack(3);

  &__text {
    @include component-text-reset();
    @include text-preset(3);
    transform: translateY(-2px); // TODO: This is janky, investigate
    font-weight: $font-weight-bold;
    color: $gray-800;
  }
}

.date {
  @include toolbar-box();

  &__text {
    @include component-text-reset();
    margin-top: -1px; // TODO: This is janky, investigate
    @include text-preset(3);
  }

  &__time {
    font-weight: $font-weight-bold;
    color: $blue-400;
    margin-left: $space-3;
    text-transform: uppercase;
  }

  &__date {
    color: $gray-400;
    font-weight: $font-weight-medium;
  }
}

.key {
  @include toolbar-box();
  @include hstack(3);

  &__label {
    @include component-text-reset();
    margin-top: -1px; // TODO: This is janky, investigate
    @include text-preset(3);
    color: $gray-400;
  }
}

.scale {
  display: flex;
  align-items: center;
}

.swatch {
  width: grid-8pt(1);
  height: grid-8pt(1);
  background-color: $gray-100;

  &:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}
