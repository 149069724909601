@import '../editor/styles.module.scss';

.pdfPageSelectModalBody {
  display: flex;
  padding: 32px;
  align-items: center;
}

.pdfPageSelectModalLabel {
  margin: 0 16px 0 0;
}
