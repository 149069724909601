@import '../../styles/input-box.scss';

.AppBarSelectBox {
  @extend %density-ui-input-box-select-box;
  width: auto;
  min-width: auto;
  background: transparent;

  .Value {
    @extend %density-ui-input-box-select-box-value;
    height: 32px;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    font-size: 16px;
    font-weight: bold;
    color: $gray800;
    border-color: transparent;
    background: transparent;
    transition: background-color ease-in-out 10x0ms;

    .IconAndText {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      .Icon {
        margin-right: 8px;
        line-height: 1px;
      }
    }

    &.opened {
      @extend %density-ui-input-box-select-box-value--opened;
      border-color: transparent;
    }
    &.disabled {
      @extend %density-ui-input-box-select-box-value--disabled;
    }

    .Placeholder {
      @extend %density-ui-input-box-select-box-placeholder;
    }
    .Caret {
      @extend %density-ui-input-box-select-box-value-caret;
      margin-left: 8px;
    }

    &:hover {
      background-color: $gray100;
      transition: background-color ease-in-out 150ms;
    }
  }

  .Menu {
    @extend %density-ui-input-box-select-box-menu;
    max-height: 320px;
    width: 360px;
    top: calc(100% + 4px);
    border-color: $gray200;
    overflow-y: auto;

    &.opened {
      @extend %density-ui-input-box-select-box-menu--opened;
    }

    ul {
      @extend %density-ui-input-box-select-box-menu-ul;
    }
    li {
      @extend %density-ui-input-box-select-box-menu-li;
      height: 28px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.appBarSelectListItemIcon {
  margin-right: 4px;
}
