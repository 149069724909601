// --------------------------------------------
// Buttons
// --------------------------------------------

.buttonSmall,
a.buttonSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  height: 32px;
  min-width: 32px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
  line-height: 1;
  font-weight: bold;
  text-decoration: none;
  // I'm sorry
  color: $midnight !important;
  cursor: pointer;
  transition: all ease-in-out 100ms;

  .icon,
  img {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 4px;
  }

  &.primary {
    // I'm sorry
    color: $white !important;
    background: $blue;

    &:hover {
      background-color: lighten($blue, 2%);
      box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
      transition: all ease-in-out 150ms;
    }
  }

  &.secondary {
    // I'm sorry
    color: $gray800 !important;
    background-color: $white;
    border: 1px solid $gray200;
  }

  &.link {
    // I'm sorry
    color: $gray800 !important;
    background-color: transparent;

    &:hover {
      // I'm sorry
      color: $blue !important;
      background-color: transparent;
    }
  }

  &.elevated {
    box-shadow: 0px 1px 2px rgba(34, 42, 46, 0.1);
  }

  &.success {
    // I'm sorry
    color: $white !important;
    background-color: $green;

    &:hover {
      background-color: lighten($green, 2%);
      box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
      transition: all ease-in-out 150ms;
    }
  }

  &.danger {
    color: $red !important;
  }
  &.danger:hover {
    background-color: $redLight;
    border-color: $redLight;
  }

  &.dark {
    color: $white !important;
  }

  & + .buttonSmall {
    margin-left: 4px;
  }

  &.buttonIcon {
    padding: 0;

    .icon,
    img {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: $blueLight;
    border-color: $blueLight;
    transition: all ease-in-out 150ms;
  }

  &:disabled,
  &.disabled {
    // background-color: $gray200;
    opacity: 0.4;
    color: $gray400;
    cursor: not-allowed;

    &:hover {
      background-color: inherit;
    }
  }
}
