.horizontalFormWrapper {
  display: flex;
  align-items: center;
}
.horizontalFormItem.sizeLarge {
  margin-right: 8px;
}
.horizontalFormItem.sizeMedium {
  margin-right: 6px;
}
.horizontalFormItem.sizeSmall {
  margin-right: 4px;
}
.horizontalFormItem:last-child {
  margin-right: 0px;
}
